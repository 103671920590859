import React from "react"
import PropTypes from "prop-types"

function SectionParagraph({ children }) {
  return <div className="text-justify">{children}</div>
}

SectionParagraph.propTypes = {
  children: PropTypes.node,
}

export default SectionParagraph
