import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function ButtonCard({ children, icon, className, to, href }) {
  const content = (
    <div
      className={`mr-1 mb-1 bg-info text-white text-center h-36 flex flex-col justify-center items-center hover:bg-opacity-90 rounded-xl cursor-pointer shadow-sm hover:shadow-md ${className}`}
    >
      {icon}
      <p className="font-bold font-quicksand text-lg lg:text-xl break-words whitespace-pre-wrap w-11/12">
        {children}
      </p>
    </div>
  )

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col w-1/2 md:w-1/3"
      >
        {content}
      </a>
    )
  } else if (to) {
    return (
      <Link to={to} className="flex flex-col w-1/2 md:w-1/3">
        {content}
      </Link>
    )
  } else {
    return <div className="flex flex-col w-1/2 md:w-1/3">{content}</div>
  }
}

ButtonCard.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
}

export default ButtonCard
