import React from "react"
import PropTypes from "prop-types"

function SectionTitle({ children, className }) {
  return (
    <>
      <h1
        className={`font-bold text-neutral ${
          className ? className : "text-4xl"
        }`}
      >
        {children}
      </h1>
      <div className="w-20 border-t-2 mt-4 pb-6 border-neutral" />
    </>
  )
}

SectionTitle.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
}

export default SectionTitle
