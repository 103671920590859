import * as React from "react"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => (
  <header className="w-full h-10 bg-primary font-quicksand font-bold text-white">
    Header
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
