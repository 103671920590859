import React from "react"
import PropTypes from "prop-types"

function Button({ children, className, isLink, to }) {
  if (isLink) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={`bg-info shadow-sm text-lg text-white px-4 py-4 rounded-lg hover:bg-opacity-90 hover:shadow-md font-quicksand cursor-pointer ${className}`}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        className={`bg-info shadow-sm text-lg text-white px-4 py-4 rounded-lg hover:bg-opacity-90 hover:shadow-md font-quicksand ${className}`}
      >
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLink: PropTypes.bool,
  to: PropTypes.string,
}

Button.defaultProps = {
  children: undefined,
  className: "",
  isLink: false,
  to: "",
}

export default Button
