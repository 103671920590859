import React from "react"
import PropTypes from "prop-types"
import { ButtonCard } from "."
import {
  HiAcademicCap,
  HiAtSymbol,
  HiBell,
  HiCalendar,
  HiUserGroup,
  HiUser,
  HiBookOpen,
  HiPuzzle,
  HiHeart,
  HiCloud,
  HiSearch,
} from "react-icons/hi"

export default function InspirationCards({ links }) {
  const icons = {
    academic: HiAcademicCap,
    at: HiAtSymbol,
    bell: HiBell,
    book: HiBookOpen,
    calendar: HiCalendar,
    cloud: HiCloud,
    group: HiUserGroup,
    heart: HiHeart,
    magnifier: HiSearch,
    puzzle: HiPuzzle,
    user: HiUser,
  }
  const colorClassNames = {
    blue: "bg-info",
    yellow: "bg-warning",
    red: "bg-error",
    green: "bg-success",
  }

  return (
    <div className="flex flex-row flex-wrap pt-2">
      {links
        .sort((A, B) => A.node.frontmatter.order - B.node.frontmatter.order)
        .map(({ node }) => {
          const { id, fields, frontmatter } = node
          const { slug } = fields
          const { url, color, icon, title } = frontmatter
          const Icon = icons[icon]

          return (
            <ButtonCard
              key={id}
              icon={<Icon className="text-3xl" />}
              className={colorClassNames[color]}
              to={slug}
              href={url}
            >
              {title}
            </ButtonCard>
          )
        })}
    </div>
  )
}

InspirationCards.propTypes = {
  links: PropTypes.array.isRequired,
}

InspirationCards.defaultProps = {
  links: [],
}
