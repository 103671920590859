import React from "react"
import PropTypes from "prop-types"

function HeroImage({ image, logo, title, logoBanner }) {
  return (
    <div className="h-screen w-full flex flex-col items-center">
      {image}
      <div className="h-full absolute top-0 w-full flex flex-col items-center justify-center bg-accent bg-opacity-70 px-10 md:px-20">
        <div className="flex-grow flex flex-row items-center justify-center w-full">
          <div className="lg:w-1/2"></div>
          <div className="w-full lg:w-1/2">
            {logo}
            <h1 className="text-4xl sm:text-5xl md:text-6xl text-white font-bold font-quicksand py-8 break-words w-full max-w-3xl">
              {title}
            </h1>
          </div>
        </div>
        <div className="pb-14 w-full">{logoBanner}</div>
      </div>
    </div>
  )
}

HeroImage.propTypes = {
  image: PropTypes.node,
  logo: PropTypes.node,
  title: PropTypes.string,
}

export default HeroImage
