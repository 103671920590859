import React from "react"
import PropTypes from "prop-types"

function Sections({ children, className }) {
  return (
    <div
      className={`w-full flex flex-col items-center py-20 space-y-20 ${className}`}
    >
      {children}
    </div>
  )
}

Sections.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Sections
