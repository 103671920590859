import React from "react"
import PropTypes from "prop-types"

function Section({ children, className, id }) {
  return (
    <div
      id={id ? id : undefined}
      className={`w-4/5 flex flex-col space-y-2 ${className}`}
    >
      {children}
    </div>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default Section
