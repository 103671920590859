import React from "react"

function Footer(props) {
  return <footer>Hey, I'm a footer.</footer>
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
